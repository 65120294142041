<template>
  <b-overlay :show="loading">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Información general de la cuenta
        </h4>
      </div>
      <div class="card-body">
        <div class="row">

          <div class="col-12">
            <div class="form-group">
              <label>Cliente</label>
              <div class="input-group">
                <v-select
                  v-model="model.customer_id"
                  label="text"
                  :options="customers"
                  :clearable="false"
                  :reduce="value=>value.getAttr('id')"
                  :get-option-label="option=>`${option.getAttr('first_name')} ${option.getAttr('last_name')}`"
                  style="flex:1;"
                  :disabled="!!model.id"
                />
                <div class="input-group-append">
                  <router-link to="/customers/create">
                    <button class="btn btn-primary">
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </button>
                  </router-link>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Monto</label>
              <input
                v-model="model.loan_amount"
                type="number"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Plazo en meses</label>
              <input
                v-model="model.term"
                type="number"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Tasa de interés (%)</label>
              <input
                v-model="model.rate"
                type="number"
                class="form-control"
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary"
              type="button"
              @click="save"
            >
              {{ model.id ? 'Editar' : 'Crear' }} solicitud
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!!model.id"
      class="card"
    >
      <div class="card-header">
        <h4 class="card-title">
          Tabla de amortización
        </h4>
      </div>
      <div class="card-body">
        <!--        <div class="row mb-2">
          <div class="col-12">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary"
              >
                <feather-icon icon="DownloadIcon" />
                Descargar formato PDF
              </button>
            </div>
          </div>
        </div>-->
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th>Periodo</th>
                <th>Saldo inicial</th>
                <th>Interés</th>
                <th>Capital</th>
                <th>Pago total</th>
                <th>Saldo final</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(period, index) in table"
                :key="index"
              >
                <td>{{ period.number }}</td>
                <td>{{ numberFormat(period.initial_balance) }}</td>
                <td>{{ numberFormat(period.interest) }}</td>
                <td>{{ numberFormat(period.amortization) }}</td>
                <td>{{ numberFormat(index===0 ? 0 : model.fixed_payment) }}</td>
                <td>{{ numberFormat(period.final_balance) }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td />
                <td />
                <td>{{ numberFormat(totalInterest) }}</td>
                <td>{{ numberFormat(totalAmortization) }}</td>
                <td>{{ numberFormat(totalToPay) }}</td>
                <td />
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary"
            >
              Otorgar préstamo
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import Customer from '@/dc-it/models/Customer'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoanRequest from '@/dc-it/models/LoanRequest'
import helpers from '@/dc-it/services/helpers'

export default {
  name: 'CreateLoanRequestView',
  components: { BOverlay, vSelect },
  methods: {
    showError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'XIcon',
          text: err,
          variant: 'warning',
        },
      })
    },
    save() {
      if (!this.model.customer_id) {
        this.showError('Debe seleccionar un cliente')
        return
      }
      if (!this.model.loan_amount || this.model.loan_amount <= 0 || Number.isNaN(this.model.loan_amount)) {
        this.showError('Debe ingresar un monto válido')
        return
      }
      if (!this.model.term || this.model.term <= 0 || Number.isNaN(this.model.term)) {
        this.showError('Debe ingresar un plazo válido')
        return
      }
      if (!this.model.rate || this.model.rate <= 0 || Number.isNaN(this.model.rate)) {
        this.showError('Debe ingresar una tasa de interés válida')
        return
      }

      if (this.loading) {
        return
      }

      this.loading = true

      const m = new LoanRequest()
      m.setAttr('id', this.model.id)
      m.setAttr('customer', this.model.customer_id)
      m.setAttr('loan_amount', this.model.loan_amount)
      m.setAttr('term', this.model.term)
      m.setAttr('rate', (this.model.rate / 100))
      m.save().then(response => {
        if (response.result === 'success') {
          m.initValues(response.data)
          this.model.fixed_payment = m.getAttr('fixed_payment', false)
          this.model.id = m.getAttr('id')

          this.table = m.getAttr('loan_request_details', false)

          this.totalInterest = 0
          this.totalToPay = 0
          this.totalAmortization = 0

          // sumar todos los intereses, pagos, amortizaciones
          this.table.forEach(period => {
            this.totalInterest += period.interest
            this.totalAmortization += period.amortization
            this.totalToPay += (period.interest + period.amortization)
          })

          /* for (let i = 0; i < this.model.term; i += 1) {
            let initialBalance = this.model.loan_amount
            if (i > 0) {
              initialBalance = this.table[i - 1].final_balance
            }
            const interest = initialBalance * m.getAttr('rate', false)
            const amortization = this.model.fixed_payment - interest
            const period = {
              number: i + 1,
              initial_balance: initialBalance,
              interest,
              amortization,
              final_balance: initialBalance - amortization,
            }
            this.table.push(period)

            this.totalInterest += interest
            this.totalAmortization += amortization
            this.totalToPay += this.model.fixed_payment
          } */
        }
      }).catch(() => {
        this.showError('Ha ocurrido un error al calcular el préstamo')
      }).finally(() => {
        this.loading = false
      })
    },
    numberFormat(number) {
      return `$ ${helpers.numberFormat(number, true)}`
    },
  },
  setup() {
    const loading = ref(true)
    const customers = ref([])
    const model = ref({
      customer_id: null,
      loan_amount: null,
      term: null,
      rate: null,
      fixed_payment: null,
      id: null,
    })

    const table = ref([])
    const totalInterest = ref(0)
    const totalAmortization = ref(0)
    const totalToPay = ref(0)

    const initData = async () => {
      loading.value = true
      const promises = [
        customers.value = await Customer.get(),
      ]

      await Promise.all(promises)

      loading.value = false
    }

    initData()

    return {
      loading,
      customers,
      model,
      table,
      totalInterest,
      totalAmortization,
      totalToPay,
    }
  },
}
</script>

<style scoped>

</style>
